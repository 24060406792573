.InputGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 10px;
  /* margin-bottom: 44px !important; */
  width: 100% !important;
  padding: 0 !important;
}
.InputGroup label {
  min-width: 100px !important;
  font-weight: 400 !important;
  color: #979797 !important;
}

@media screen and (max-width: 500px) {
  .InputGroup {
    flex-direction: column;
    align-items: flex-start;
  }
}
