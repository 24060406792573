.dashboard-outlet-name {
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: rgb(95, 92, 92) !important;
}
.dashboard-header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: space-between;
}
.dashboard-date-filter-box {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-calendar-icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
}
.custom-date-picker {
  padding: 12px !important;
}
.center-title-chart {
  font-weight: 500 !important;
  font-size: 18px;
}
.buttonGroup {
  float: right;
  padding: 0 20px;
}
