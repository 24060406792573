.wrapper {
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  justify-content: center;
  background-image: linear-gradient(
    var(--light_xabit_orange),
    var(--xabit_orange)
  ) !important;
  color: #efefef;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-width: 150px;
  width: 30%;
  height: 130px;
  border-radius: 15px;
}

.number {
  color: #efefef;
  font-weight: 700 !important;
  font-size: x-large;
  letter-spacing: 1px;
  margin: 0;
}

.text {
  font-size: small;
  margin: 0;
  color: #efefef;
  font-weight: 500;
}
