@font-face {
  font-family: intro_rust;
  src: url(../fonts/IntroRust-Base.otf);
}

@font-face {
  font-family: comfortaa_bold;
  src: url(../fonts/Comfortaa-Bold.ttf);
}

:root {
  --light_gray: #d9d7d7;
  --light_gray_dark: #a3a3a3;
  --light_black: #393939;
  --light_black_dark: #1a1a1a;
  --light_xabit_orange: #fa7766;
  --xabit_orange: #f96123;
  --btn_green: #4cd170;
  --btn_green_dark: #00b951;
  --btn_red: #e23520;
  --btn_red_dark: #cc0000;
  --btn_yellow: #efcb4b;
  --btn_yellow_dark: #ebc231;
}

.button-dark-gray {
  background-image: linear-gradient(
    var(--light_black),
    var(--light_black)
  ) !important;
  color: var(--light_black) !important;
  opacity: 0.8;
}

.button-dark-gray.btn-secondary:hover,
.button-dark-gray.btn-secondary:active,
.button-dark-gray.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--light_black_dark),
    var(--light_black_dark)
  ) !important;
  color: white !important;
  text-decoration: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: var(--light_black_dark) !important;
}

.button-light-gray {
  background-image: linear-gradient(
    var(--light_gray),
    var(--light_gray)
  ) !important;
  color: var(--light_black) !important;
  opacity: 0.8;
}

.button-light-gray.btn-secondary:hover,
.button-light-gray.btn-secondary:active,
.button-light-gray.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.button-green {
  background-image: linear-gradient(
    var(--btn_green),
    var(--btn_green)
  ) !important;
}

.button-green.btn-secondary:hover,
.button-green.btn-secondary:active,
.button-green.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--btn_green_dark),
    var(--btn_green_dark)
  ) !important;
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.button-red {
  background-image: linear-gradient(var(--btn_red), var(--btn_red)) !important;
}

.button-red.btn-secondary:hover,
.button-red.btn-secondary:active,
.button-red.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--btn_red_dark),
    var(--btn_red_dark)
  ) !important;
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.button-yellow {
  background-image: linear-gradient(
    var(--btn_yellow),
    var(--btn_yellow)
  ) !important;
}

.button-yellow.btn-secondary:hover,
.button-yellow.btn-secondary:active,
.button-yellow.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--btn_yellow_dark),
    var(--btn_yellow_dark)
  ) !important;
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.button-orange {
  background-image: linear-gradient(
    var(--light_xabit_orange),
    var(--light_xabit_orange)
  ) !important;
}

.button-orange.btn-secondary:hover,
.button-orange.btn-secondary:active,
.button-orange.btn-secondary:focus {
  background-color: transparent !important;
  background-image: linear-gradient(
    var(--xabit_orange),
    var(--xabit_orange)
  ) !important;
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.button-light-gray.btn-simple.active,
.button-light-gray.btn-info.active,
.button-light-gray.btn-info.btn-simple.active {
  color: white !important;
  border-color: var(--light_black) !important;
  background-image: linear-gradient(
    var(--light_black),
    var(--light_black)
  ) !important;
  background-image: -webkit-linear-gradient(
    var(--light_black),
    var(--light_black)
  ) !important;
  background-image: -o-linear-gradient(
    var(--light_black),
    var(--light_black)
  ) !important;
  background-image: -moz-linear-gradient(
    var(--light_black),
    var(--light_black)
  ) !important;
  background-color: var(--light_black) !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.button-light-gray.btn-simple.active:hover,
.button-light-gray.btn-simple.active:focus,
.button-light-gray.btn-simple.active:active,
.button-light-gray.btn-simple.active:not(:disabled):not(.disabled):active
  .button-light-gray.btn-info.active:hover,
.button-light-gray.btn-info.active:focus,
.button-light-gray.btn-info.active:active,
.button-light-gray.btn-info.active:not(:disabled):not(.disabled):active,
.button-light-gray.btn-info.btn-simple.active:hover,
.button-light-gray.btn-info.btn-simple.active:focus,
.button-light-gray.btn-info.btn-simple.active:active,
.button-light-gray.btn-info.btn-simple.active:not(:disabled):not(.disabled):active {
  color: white !important;
  border-color: var(--light_gray_dark) !important;
  background-image: linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -webkit-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -o-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -moz-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-color: var(--light_gray_dark) !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5) !important;
}

.button-light-gray.btn-simple,
.button-light-gray.btn-info,
.button-light-gray.btn-info.btn-simple {
  color: var(--light_black) !important;
  border-color: var(--light_gray) !important;
  background-image: linear-gradient(
    var(--light_gray),
    var(--light_gray)
  ) !important;
  background-image: -webkit-linear-gradient(
    var(--light_gray),
    var(--light_gray)
  ) !important;
  background-image: -o-linear-gradient(
    var(--light_gray),
    var(--light_gray)
  ) !important;
  background-image: -moz-linear-gradient(
    var(--light_gray),
    var(--light_gray)
  ) !important;
  background-color: var(--light_gray) !important;
  box-shadow: none !important;
  opacity: 0.8;
}

.button-light-gray.btn-simple:hover,
.button-light-gray.btn-simple:focus,
.button-light-gray.btn-simple:active,
.button-light-gray.btn-simple:not(:disabled):not(.disabled):active
  .button-light-gray.btn-info:hover,
.button-light-gray.btn-info:focus,
.button-light-gray.btn-info:active,
.button-light-gray.btn-info:not(:disabled):not(.disabled):active,
.button-light-gray.btn-info.btn-simple:hover,
.button-light-gray.btn-info.btn-simple:focus,
.button-light-gray.btn-info.btn-simple:active,
.button-light-gray.btn-info.btn-simple:not(:disabled):not(.disabled):active {
  color: white !important;
  border-color: var(--light_gray_dark) !important;
  background-image: linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -webkit-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -o-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-image: -moz-linear-gradient(
    var(--light_gray_dark),
    var(--light_gray_dark)
  ) !important;
  background-color: var(--light_gray_dark) !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5) !important;
}

.form_input:active,
.form_input:focus {
  border-color: var(--xabit_orange) !important;
}

h2.font-intro {
  font-family: "Poppins" !important;
}

.main-panel {
  background-color: #f4f4f4 !important;
}

.alert_error {
  color: red;
  letter-spacing: 1px;
  font-size: 0.7pc;
  opacity: 0.6;
}

.copyright_mid {
  font-family: comfortaa_bold;
  width: 98%;
  letter-spacing: 1px;
  font-size: 0.7pc;
}

.product_input {
  width: 70% !important;
}
.custom-input {
  width: 60% !important;
}
.custom-label {
  width: auto !important;
}
.drop-zone-container {
  width: 100%;
  height: 120px;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  justify-content: center;
  margin: 0;
  padding: 0 !important;
  border-radius: 10px;
  border: 2px solid #efefef;
  cursor: pointer;
}
.dropzone-edit-image {
  width: 30px;
  height: 30px;
  background: #dfdbdb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  right: -30px;
  margin: 0;
}
.drop-zone-container img {
  height: 30%;
}
.drop-zone-container span {
  font-size: 12px;
  color: #979797;
  text-align: center;

  margin: 0 20px;
}
.drop-zone-container .span-blue {
  color: #304ffe;
  margin: 0 !important;
}
.drop-zone-container .active {
  height: 100% !important;
  width: 100% !important;
}
/* .card-title {
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
} */
.button-group-product {
  position: absolute !important;
  display: flex;
  top: 50%;
  right: 25px;
  gap: 12px;
  transform: translateY(-50%);
}
.create-product-card {
  height: auto !important;
}
.header-product-card {
  position: relative;
  height: 75px;
}
.cancel-button,
.create-button {
  height: 35px;
  width: 110px;
  font-size: 14px !important;
  justify-content: center;
  display: flex !important;
  align-items: center !important;
  gap: 7.5px;
  border: 1px solid #b1aeae !important;
}
.cancel-button {
  background: #eeeded !important;
  color: #5f5e5e !important;
}
.cancel-button:hover {
  background-color: #e2e1e1 !important;
  box-shadow: none !important;
  background-image: none !important;
}
.media-label-product {
  font-size: 14px;
  color: #979797 !important;
  opacity: 1 !important;
  align-self: flex-start;
}
.product-name {
  margin-bottom: 20px;
}
.media-product-container {
  margin-top: -44px !important;
}
.icon-outline-product {
  width: 50px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #eeeded;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #b1aeaeb2;
}
.product-default-outlets {
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  min-height: 135px;
  background: #eeeded;
  border: 1px solid #939393de;
  padding: 17px 30px;
  border-radius: 5px;
  gap: 10px;
  align-items: flex-end;
}
.button-group-outlets {
  display: flex;
  float: right;
}
.product-multi-select {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}
.modal-button-group {
  display: flex;
  gap: 10px;
}
.modal-button-group button {
  padding: 0 20px;
}
.product-multi-select label {
  font-size: 14px;
  color: #393939 !important;
}
.product_input {
  margin-bottom: 44px !important;
}
.product-multi-select-input-container {
  flex-grow: 1;
}

.order-payment-type{
  text-transform: lowercase;
}

.order-payment-type::first-letter{
  text-transform: capitalize;
}
@media screen and (max-width: 700px) {
  .drop-zone-container div {
    width: 50% !important;
  }
  .product-card-header {
    height: 100px;
  }
  .button-group-product {
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
  /* .card-title {
    transform: translate(-50%, 0);
    top: 20px;
  } */
}
